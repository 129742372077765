
import useMarketplaceChain from "hooks/useMarketplaceChain";
import {Box, Flex ,Text} from "../primitives";
import card from "../../public/index/card.png"
import card01 from "../../public/index/card_01.png"
import card02 from "../../public/index/card_02.png"
import card03 from "../../public/index/card_04.png"
import 'swiper/css';
import 'swiper/css/effect-fade';
import {Autoplay, EffectFade, Navigation, Pagination} from 'swiper/modules'
type TabValue = 'collections' | 'mints'
import { paths } from '@reservoir0x/reservoir-sdk'
import { ChainContext } from 'context/ChainContextProvider'
import { GetServerSideProps, InferGetServerSidePropsType, NextPage } from 'next'
import {
    ComponentPropsWithoutRef,
    useContext,
    useEffect,
    useState,
} from 'react'
import { useRouter } from "next/router";
import supportedChains from 'utils/chains'
import {
    useTrendingCollections,
    useTrendingMints,
} from '@reservoir0x/reservoir-kit-ui'
import {
    CollectionsSortingOption,
} from 'components/common/CollectionsTimeDropdown'
import {
    MintsSortingOption,
} from 'components/common/MintsPeriodDropdown'
import { CollectionRankingsTable } from 'components/rankings/CollectionRankingsTable'
import { useTheme } from 'next-themes'
import { useMediaQuery } from 'react-responsive'
import fetcher from 'utils/fetcher'
import Image from "next/image";
import Link from "next/link";

type Props = InferGetServerSidePropsType<typeof getServerSideProps>
const NFTBanner: NextPage = () => {
    const router = useRouter()
    const { routePrefix } = useMarketplaceChain()
    // not sure if there is a better way to fix this
    const { theme: nextTheme } = useTheme()
    const [theme, setTheme] = useState<string | null>(null)
    useEffect(() => {
        if (nextTheme) {
            setTheme(nextTheme)
        }
    }, [nextTheme])

    const isSSR = typeof window === 'undefined'
    const isSmallDevice = useMediaQuery({ query: '(max-width: 800px)' })
    const [bgCard,setBgCard] = useState(card)

    const [tab, setTab] = useState<TabValue>('collections')
    const [sortByTime, setSortByTime] = useState<CollectionsSortingOption>('24h')

    const [sortByPeriod, setSortByPeriod] = useState<MintsSortingOption>('24h')
    const [cardSrc,setCardSrc] = useState([card,card01,card02,card03])
    let [countCard,setCountCard] = useState(0)
    let mintsQuery: Parameters<typeof useTrendingMints>['0'] = {
        limit: 20,
        period: sortByPeriod,
        type: 'any',
    }

    const { chain, switchCurrentChain } = useContext(ChainContext)

    useEffect(() => {
        if (router.query.chain) {
            let chainIndex: number | undefined
            for (let i = 0; i < supportedChains.length; i++) {
                if (supportedChains[i].routePrefix == router.query.chain) {
                    chainIndex = supportedChains[i].id
                }
            }
            if (chainIndex !== -1 && chainIndex) {
                switchCurrentChain(chainIndex)
            }
        }
    }, [router.query])

    const {
        data: trendingCollections,
        isValidating: isTrendingCollectionsValidating,
    } = useTrendingCollections(
        {
            limit: 20,
            sortBy: 'volume',
            period: sortByTime,
        },
        chain.id,
        {
            fallbackData: "",
            keepPreviousData: true,
        }
    )
    const {
        data: featuredCollections,
        isValidating: isFeaturedCollectionsValidating,
    } = useTrendingCollections(
        {
            limit: 20,
            sortBy: 'sales',
            period: '24h',
        },
        chain.id,
        {
            fallbackData: "",
            keepPreviousData: true,
        }
    )
    const { data: trendingMints, isValidating: isTrendingMintsValidating } =
        useTrendingMints({ ...mintsQuery }, chain.id, {
            fallbackData: "",
            keepPreviousData: true,
        })
    let volumeKey: ComponentPropsWithoutRef<
        typeof CollectionRankingsTable
    >['volumeKey'] = '1day'

    switch (sortByTime) {
        case '30d':
            volumeKey = '30day'
            break
        case '7d':
            volumeKey = '7day'
            break
        case '24h':
            volumeKey = '1day'
            break
    }

    let count = 0
    useEffect(()=>{
       const intervalId = setInterval(() => {
            count++
            if (count < cardSrc.length) {
                setBgCard(cardSrc[count])
            }else {
                count = 0
            }
        }, 5000);

        return () => {
            clearInterval(intervalId);
        };
    },[])
    return (
        <Box >
            <Box css={{
                width:"100%",
                gap:24,
                "@md":{
                    height:"100vh"
                }
            }}>
                <figure style={{
                    height:"100%",
                    margin: "0",
                    padding: "0",
                    position: "relative",
                    display: "block",
                    overflow: "hidden",
                }}>
                    <Box css={{
                        display:"none",
                        "@md":{
                            display:"block",
                        }
                    }}>
                        <Image src={bgCard}  alt="" style={{
                            width: "100%",
                            display: "block",
                            filter: "blur(90px)",/* 对图片应用模糊效果 */
                            transform: "translateZ(0)", /* 图片略微放大以减少模糊带来的影响 */
                            position:"relative",
                            transition:"opacity 1s ease"
                        }} />
                    </Box>


                    <Flex justify="between"  align='center' css={{
                        gap:24,
                        paddingLeft:'$6',
                        paddingRight:'$6',
                        flexDirection:"column",
                        "@md": {
                            padding:"$6",
                            position:"absolute",
                            top:"0",
                            left:"0",
                            right:"0",
                            flexDirection:"row"
                        }
                    }}>
                        <Box css={{
                            maxWidth:"620px"
                        }}>
                            <Text css={{
                                fontSize:"60px",
                                fontWeight:"900",
                               "@md":{
                                   fontSize:"80px",
                                   fontWeight:"900",
                                   lineHeight:"1.1",
                                   letterSpacing:"8px"
                               }
                            }}>
                                Discover, sell and collect NFTs
                            </Text>
                        </Box>
                        <Box  css={{
                            width:"500px",
                            background:"#FFF",
                            boxShadow: "0 0 100px #000",
                            "@md": {
                                width:"500px",
                                background:"#FFF",
                                boxShadow: "0 0 100px #000",
                            }
                        }}>
                                <Image src={bgCard} alt=""/>

                            <Box css={{
                                height:"10px"
                            }}>

                            </Box>
                        <Text style="body1" css={{
                            padding:"$6",
                            color:"#000",   
                            "@md":{
                                padding:"$4",
                                marginTop:"$2",
                                color:"#000"
                            }

                        }}>
                            Kreature Kastle</Text>
                            <Box css={{
                                height:"20px"
                            }}></Box>

                            <Flex align="center" justify="between" css={{
                                paddingLeft:"$6",
                                paddingRight:"$6",
                                "@md":{
                                    paddingLeft:"$4",
                                    paddingRight:"$6"
                                }
                            }}>
                                <Text style="body1" css={{
                                    color:"#000"
                                }}>View details</Text>

                                <Box css={{
                                    background:"#5165ff",
                                    padding:"$4",
                                    borderRadius:"5px",
                                    color:"#fff",
                                    fontSize:"16px",
                                    marginBottom:"$4"
                                }}>
                                    <Link href={`/${routePrefix}/collection/${featuredCollections && featuredCollections[0]?.id}`}  >View details</Link>
                                </Box>
                            </Flex>

                        </Box>
                    </Flex>
                </figure>
            </Box>
    </Box>
)
}

type TrendingCollectionsSchema =
paths['/collections/trending/v1']['get']['responses']['200']['schema']
type TrendingMintsSchema =
paths['/collections/trending-mints/v1']['get']['responses']['200']['schema']

export const getServerSideProps: GetServerSideProps<{
ssr: {
    trendingMints: TrendingMintsSchema
    trendingCollections: TrendingCollectionsSchema
    featuredCollections: TrendingCollectionsSchema
}
}> = async ({ params, res }) => {
const chainPrefix = params?.chain || ''
const reservoirBaseUrl = process.env.NEXT_PUBLIC_HOST_URL
// init BaseApi
// supportedChains.find((chain) => chain.routePrefix === chainPrefix) ||
// DefaultChain
// console.log("请求的路劲",reservoirBaseUrl)
const headers: RequestInit = {
    headers: {
        'x-api-key': process.env.RESERVOIR_API_KEY || '',
    },
}
let trendingCollectionsQuery: paths['/collections/trending/v1']['get']['parameters']['query'] =
    {
        period: '24h',
        limit: 20,
        sortBy: 'volume',
    }

const trendingCollectionsPromise = fetcher(
    `${reservoirBaseUrl}/collections/trending/v1`,
    trendingCollectionsQuery,
    headers
)
let featuredCollectionQuery: paths['/collections/trending/v1']['get']['parameters']['query'] =
    {
        period: '24h',
        limit: 20,
        sortBy: 'sales',
    }

const featuredCollectionsPromise = fetcher(
    `${reservoirBaseUrl}/collections/trending/v1`,
    featuredCollectionQuery,
    headers
)

let trendingMintsQuery: paths['/collections/trending-mints/v1']['get']['parameters']['query'] =
    {
        period: '24h',
        limit: 20,
        type: 'any',
    }

const trendingMintsPromise = fetcher(
    `${reservoirBaseUrl}/collections/trending-mints/v1`,
    trendingMintsQuery,
    headers
)

const promises = await Promise.allSettled([
    trendingCollectionsPromise,
    featuredCollectionsPromise,
    trendingMintsPromise,
]).catch((e) => {
    console.error(e)
})

const trendingCollections: Props['ssr']['trendingCollections'] =
    promises?.[0].status === 'fulfilled' && promises[0].value.data
        ? (promises[0].value.data as Props['ssr']['trendingCollections'])
        : {}
const featuredCollections: Props['ssr']['featuredCollections'] =
    promises?.[1].status === 'fulfilled' && promises[1].value.data
        ? (promises[1].value.data as Props['ssr']['featuredCollections'])
        : {}

const trendingMints: Props['ssr']['trendingMints'] =
    promises?.[1].status === 'fulfilled' && promises[1].value.data
        ? (promises[1].value.data as Props['ssr']['trendingMints'])
        : {}

res.setHeader(
    'Cache-Control',
    'public, s-maxage=120, stale-while-revalidate=180'
)
return {
    props: { ssr: { trendingCollections, trendingMints, featuredCollections } },
}
}
export default NFTBanner